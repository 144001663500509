<template>
  <div class="container" :class="language">
    <ValidationObserver ref="form">
    <div class="row justify-content-center">
      <div class="col-12 comment-form">
        <select-input :label="$t('representationForm.ostan')"  :options="ostan" id="ostan" :change="fetchShahr()" @input="setSelected($event,'ostan')"></select-input>
        <select-input :label="$t('representationForm.shahr')"  :options="shahr" id="shahr"  @input="setSelected($event,'shahr')"></select-input>
      </div>
      <div class="col-12 comment-form">
        <text-input :label="$t('representationForm.addr')" type="text" id="addr" v-model="form.addr"></text-input>
      </div>
      <div class="col-12 comment-form">
        <text-input rules="required" :label="$t('representationForm.certificateExpire')" type="text" id="javaz" v-model="form.javaz"></text-input>
      </div>
      <div class="col-12 comment-form">
        <select-input :label="$t('representationForm.namayandegi')" type="text" id="oldRepresentation" :options="bloen" @input="setSelected($event,'oldRepresentation')"></select-input>
      </div>
      <div class="col-12 comment-form">
        <text-input rules="required" :label="$t('representationForm.oldWork')" type="text" id="oldWork" v-model="form.oldWork"></text-input>
      </div>
      <div class="col-12 comment-form">
        <text-input rules="required|numeric" :label="$t('representationForm.naghdi')" type="text" id="cash" v-model="form.cash"></text-input>
      </div>
      <div class="col-12 comment-form">
        <text-input rules="required|numeric" :label="$t('representationForm.gheyrenaghdi')" type="text" id="nonCash" v-model="form.noneCash"></text-input>
      </div>
    </div>
  </ValidationObserver>
  </div>
</template>

<script>
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
import * as VeeValidate from 'vee-validate';
export default {
  name:'Place',
  data(){
    return {
      language:this.$route.params.language,
      bloen:[
        {label:'بلی' , code:1},
        {label:'خیر' , code:0}
      ],
      ostan:[
        {code:"8", label:"تهران"},
        {code:"25", label:"گیلان"},
        {code:"1", label:"آذربایجان شرقی"},
        {code:"13", label:"خوزستان"},
        {code:"17", label:"فارس"},
        {code:"4", label:"اصفهان"},
        {code:"11", label:"خراسان رضوی"},
        {code:"18", label:"قزوین"},
        {code:"15", label:"سمنان"},
        {code:"19", label:"قم"},
        {code:"28", label:"مرکزی"},
        {code:"14", label:"زنجان"},
        {code:"27",label:"مازندران"},
        {code:"24",label:"گلستان"},
        {code:"3", label:"اردبیل"},
        {code:"2", label:"آذربایجان غربی"},
        {code:"30", label:"همدان"},
        {code:"20", label:"کردستان"},
        {code:"22", label:"کرمانشاه"},
        {code:"26", label:"لرستان"},
        {code:"7", label:"بوشهر"},
        {code:"21", label:"کرمان"},
        {code:"29", label:"هرمزگان"},
        {code:"9", label:"چهارمحال و بختیاری"},
        {code:"31", label:"یزد"},
        {code:"16", label:"سیستان و بلوچستان"},
        {code:"6", label:"ایلام"},
        {code:"23", label:"کهگلویه و بویراحمد"},
        {code:"12", label:"خراسان شمالی"},
        {code:"10", label:"خراسان جنوبی"},
        {code:"5", label:"البرز"}
      ],
      shahrSample:{
  "1": [
    {code:"1",label: "اسکو"},
    {code:"2",label: "اهر"},
    {code:"3",label: "آذرشهر"},
    {code:"4",label: "بستان آباد"},
    {code:"5",label: "بناب"},
    {code:"6",label: "تبریز"},
    {code:"7",label: "جلفا"},
    {code:"8",label: "چاراویماق"},
    {code:"9",label: "خداآفرین"},
    {code:"10",label: "سراب"},
    {code:"11",label: "شبستر"},
    {code:"12",label: "عجب شیر"},
    {code:"13",label: "کلیبر"},
    {code:"14",label: "مراغه"},
    {code:"15",label: "مرند"},
    {code:"16",label: "ملکان"},
    {code:"17",label: "میانه"},
    {code:"18",label: "ورزقان"},
    {code:"19",label: "هریس"},
    {code:"20",label: "هشترود"}
  ],
  "2": [
   {code: "1",label: "ارومیه"},
   {code: "2",label: "اشنویه"},
   {code: "3",label: "بوكان"},
   {code: "4",label: "پلدشت"},
   {code: "5",label: "پیرانشهر"},
   {code: "6",label: "تكاب"},
   {code: "7",label: "چالدران"},
   {code: "8",label: "چایپاره"},
   {code: "9",label: "خوئ"},
    {code:"10",label: "سردشت"},
    {code:"11",label: "سلماس"},
    {code:"12",label: "شاهین دژ"},
    {code:"13",label: "شوط"},
    {code:"14",label: "ماكو"},
    {code:"15",label: "مهاباد"},
    {code:"16",label: "میاندوآب"},
    {code:"17",label: "نقده"}
  ],
  "3": [
  {code: "1",label: "اردبیل"},
  {code: "2",label: "بیله سوار"},
  {code: "3",label: "پارس آباد"},
  {code: "4",label: "خلخال"},
  {code: "5",label: "سرعین"},
  {code: "6",label: "كوثر"},
  {code: "7",label: "گرمی"},
  {code: "8",label: "مشگین شهر"},
  {code: "9",label: "نمین"},
   {code:"10",label: "نیر"}
  ],
  "4": [
   {code: "1",label:"اردستان"},
   {code: "2",label:"اصفهان"},
   {code: "3",label:"آران وبیدگل"},
   {code: "4",label:"برخوار"},
   {code: "5",label:"بو یین و میاندشت"},
   {code: "6",label:"تیران وکرون"},
   {code: "7",label:"چادگان"},
   {code: "8",label:"خمینی شهر"},
   {code: "9",label:"خوانسار"},
    {code:"10",label:"خور و بیابانک"},
    {code:"11",label:"دهاقان"},
    {code:"12",label:"سمیرم"},
    {code:"13",label:"شاهین شهرومیمه"},
    {code:"14",label:"شهرضا"},
    {code:"15",label:"فریدن"},
    {code:"16",label:"فریدونشهر"},
    {code:"17",label:"فلاورجان"},
    {code:"18",label:"کاشان"},
    {code:"19",label:"گلپایگان"},
    {code:"20",label:"لنجان"},
    {code:"21",label:"مبارکه"},
    {code:"22",label:"نایین"},
    {code:"23",label:"نجف آباد"},
    {code:"24",label:"نطنز"}
  ],
  "5": [
    {code:"1",label:"اشتهارد"},
    {code:"2",label:"ساوجبلاغ"},
    {code:"3",label:"طالقان"},
    {code:"4",label:"فردیس"},
    {code:"5",label:"کرج"},
    {code:"6",label:"نظرآباد"}
  ],
  "6": [
   {cose: "1",label: "ایلام"},
   {cose: "2",label: "ایوان"},
   {cose: "3",label: "آبدانان"},
   {cose: "4",label: "بدره"},
   {cose: "5",label: "چرداول"},
   {cose: "6",label: "دره شهر"},
   {cose: "7",label: "دهلران"},
   {cose: "8",label: "سیروان"},
   {cose: "9",label: "ملكشاهی"},
    {cose:"10",label: "مهران"}
  ],
  "7": [
   {code: "1",label: "بوشهر"},
   {code: "2",label: "تنگستان"},
   {code: "3",label: "جم"},
   {code: "4",label: "دشتستان"},
   {code: "5",label: "دشتی"},
   {code: "6",label: "دیر"},
   {code: "7",label: "دیلم"},
   {code: "8",label: "عسلویه"},
   {code: "9",label: "كنگان"},
    {code:"10",label: "گناوه"}
  ],
  "8": [
   {code: "1",label: "اسلامشهر"},
   {code: "2",label: "بهارستان"},
   {code: "3",label: "پاكدشت"},
   {code: "4",label: "پردیس"},
   {code: "5",label: "پیشوا"},
   {code: "6",label: "تهران"},
   {code: "7",label: "دماوند"},
   {code: "8",label: "رباطكریم"},
   {code: "9",label: "رئ"},
    {code:"10",label: "شمیرانات"},
    {code:"11",label: "شهریار"},
    {code:"12",label: "فیروزكوه"},
    {code:"13",label: "قدس"},
    {code:"14",label: "قرچک"},
    {code:"15",label: "ملارد"},
    {code:"16",label: "ورامین"}
  ],
  "9": [
    {code:"1",label:"اردل"},
    {code:"2",label:"بروجن"},
    {code:"3",label:"بن"},
    {code:"4",label:"سامان"},
    {code:"5",label:"شهركرد"},
    {code:"6",label:"فارسان"},
    {code:"7",label:"كوهرنگ"},
    {code:"8",label:"كیار"},
    {code:"9",label:"لردگان"}
  ],
  "10": [
   {code: "1",label:"بشرویه"},
   {code: "2",label:"بیرجند"},
   {code: "3",label:"خوسف"},
   {code: "4",label:"درمیان"},
   {code: "5",label:"زیرکوه"},
   {code: "6",label:"سرایان"},
   {code: "7",label:"سربیشه"},
   {code: "8",label:"طبس"},
   {code: "9",label:"فردوس"},
    {code:"10",label:"قائنات"},
    {code:"11",label:"نهبندان"}
  ],
  "11": [
   {code: "1",label: "باخرز"},
   {code: "2",label: "بجستان"},
   {code: "3",label: "بردسكن"},
   {code: "4",label: "بینالود"},
   {code: "5",label: "تایباد"},
   {code: "6",label: "تربت جام"},
   {code: "7",label: "تربت حیدریه"},
   {code: "8",label: "جغتای"},
   {code: "9",label: "جوین"},
    {code:"10",label: "چناران"},
    {code:"11",label: "خلیل آباد"},
    {code:"12",label: "خواف"},
    {code:"13",label: "خوشاب"},
    {code:"14",label: "داورزن"},
    {code:"15",label: "درگز"},
    {code:"16",label: "رشتخوار"},
    {code:"17",label: "زاوه"},
    {code:"18",label: "سبزوار"},
    {code:"19",label: "سرخس"},
    {code:"20",label: "فریمان"},
    {code:"21",label: "فیروزه"},
    {code:"22",label: "قوچان"},
    {code:"23",label: "كاشمر"},
    {code:"24",label: "كلات"},
    {code:"25",label: "گناباد"},
    {code:"26",label: "مشهد"},
    {code:"27",label: "مه ولات"},
    {code:"28",label: "نیشابور"}
  ],
  "12": [
    {code:"1",label: "اسفراین"},
    {code:"2",label: "بجنورد"},
    {code:"3",label: "جاجرم"},
    {code:"4",label: "راز و جرگلان"},
    {code:"5",label: "شیروان"},
    {code:"6",label: "فاروج"},
    {code:"7",label: "گرمه"},
    {code:"8",label: "مانه وسملقان"}
  ],
  "13": [
   {code: "1",label: "امیدیه"},
   {code: "2",label: "اندیکا"},
   {code: "3",label: "اندیمشک"},
   {code: "4",label: "اهواز"},
   {code: "5",label: "ایذه"},
   {code: "6",label: "آبادان"},
   {code: "7",label: "آغاجاری"},
   {code: "8",label: "باغ ملک"},
   {code: "9",label: "باوی"},
    {code:"10",label: "بندرماهشهر"},
    {code:"11",label: "بهبهان"},
    {code:"12",label: "حمیدیه"},
    {code:"13",label: "خرمشهر"},
    {code:"14",label: "دزفول"},
    {code:"15",label: "دشت آزادگان"},
    {code:"16",label: "رامشیر"},
    {code:"17",label: "رامهرمز"},
    {code:"18",label: "شادگان"},
    {code:"19",label: "شوش"},
    {code:"20",label: "شوشتر"},
    {code:"21",label: "کارون"},
    {code:"22",label: "گتوند"},
    {code:"23",label: "لالی"},
    {code:"24",label: "مسجدسلیمان"},
    {code:"25",label: "هفتگل"},
    {code:"26",label: "هندیجان"},
    {code:"27",label: "هویزه"}
  ],
  "14": [
    {code:"1",label: "ابهر"},
    {code:"2",label: "ایجرود"},
    {code:"3",label: "خدابنده"},
    {code:"4",label: "خرمدره"},
    {code:"5",label: "زنجان"},
    {code:"6",label: "سلطانیه"},
    {code:"7",label: "طارم"},
    {code:"8",label: "ماهنشان"}
  ],
  "15": [
    {code:"1",label: "آرادان"},
    {code:"2",label: "دامغان"},
    {code:"3",label: "سرخه"},
    {code:"4",label: "سمنان"},
    {code:"5",label: "شاهرود"},
    {code:"6",label: "گرمسار"},
    {code:"7",label: "مهدئ شهر"},
    {code:"8",label: "میامی"}
  ],
  "16": [
   {code: "1",label: "ایرانشهر"},
   {code: "2",label: "چاه بهار"},
   {code: "3",label: "خاش"},
   {code: "4",label: "دلگان"},
   {code: "5",label: "زابل"},
   {code: "6",label: "زاهدان"},
   {code: "7",label: "زهك"},
   {code: "8",label: "سراوان"},
   {code: "9",label: "سرباز"},
    {code:"10",label: "سیب و سوران"},
    {code:"11",label: "فنوج"},
    {code:"12",label: "قصرقند"},
    {code:"13",label: "كنارك"},
    {code:"14",label: "مهرستان"},
    {code:"15",label: "میرجاوه"},
    {code:"16",label: "نیك شهر"},
    {code:"17",label: "نیمروز"},
    {code:"18",label: "هامون"},
    {code:"19",label: "هیرمند"}
  ],
  "17": [
    {code:"1",label:"ارسنجان"},
    {code:"2",label:"استهبان"},
    {code:"3",label:"اقلید"},
    {code:"4",label:"آباده"},
    {code:"5",label:"بوانات"},
    {code:"6",label:"پاسارگاد"},
    {code:"7",label:"جهرم"},
    {code:"8",label:"خرامه"},
    {code:"9",label:"خرم بید"},
    {code:"10",label:"خنج"},
    {code:"11",label:"داراب"},
    {code:"12",label:"رستم"},
    {code:"13",label:"زرین دشت"},
    {code:"14",label:"سپیدان"},
    {code:"15",label:"سروستان"},
    {code:"16",label:"شیراز"},
    {code:"17",label:"فراشبند"},
    {code:"18",label:"فسا"},
    {code:"19",label:"فیروزآباد"},
    {code:"20",label:"قیروکارزین"},
    {code:"21",label:"کازرون"},
    {code:"22",label:"کوار"},
    {code:"23",label:"گراش"},
    {code:"24",label:"لارستان"},
    {code:"25",label:"لامرد"},
    {code:"26",label:"مرودشت"},
    {code:"27",label:"ممسنی"},
    {code:"28",label:"مهر"},
    {code:"29",label:"نی ریز"}
  ],
  "18": [
    {code:"1",label:"البرز"},
    {code:"2",label:"آبیك"},
    {code:"3",label:"آوج"},
    {code:"4",label:"بوئین زهرا"},
    {code:"5",label:"تاكستان"},
    {code:"6",label:"قزوین"}
  ],
  "19": [
    {code:"1",label: "قم"}
  ],
  "20": [
    {code:"1",label: "مریوان"}
  ],
  "21": [
    {code:"1",label: "ارزوئیه"},
    {code:"2",label: "انار"},
    {code:"3",label: "بافت"},
    {code:"4",label: "بردسیر"},
    {code:"5",label: "بم"},
    {code:"6",label: "جیرفت"},
    {code:"7",label: "رابر"},
    {code:"8",label: "راور"},
    {code:"9",label: "رفسنجان"},
    {code:"10",label: "رودبارجنوب"},
    {code:"11",label: "ریگان"},
    {code:"12",label: "زرند"},
    {code:"13",label: "سیرجان"},
    {code:"14",label: "شهربابك"},
    {code:"15",label: "عنبرآباد"},
    {code:"16",label: "فاریاب"},
    {code:"17",label: "فهرج"},
    {code:"18",label: "قلعه گنج"},
    {code:"19",label: "كرمان"},
    {code:"20",label: "كوهبنان"},
    {code:"21",label: "كهنوج"},
    {code:"22",label: "منوجان"},
    {code:"23",label: "نرماشیر"}
  ],
  "22": [
    {code:"1",label:"اسلام آبادغرب"},
    {code:"2",label:"پاوه"},
    {code:"3",label:"ثلاث باباجانی"},
    {code:"4",label:"جوانرود"},
    {code:"5",label:"دالاهو"},
    {code:"6",label:"روانسر"},
    {code:"7",label:"سرپل ذهاب"},
    {code:"8",label:"سنقر"},
    {code:"9",label:"صحنه"},
    {code:"10",label:"قصرشیرین"},
    {code:"11",label:"کرمانشاه"},
    {code:"12",label:"کنگاور"},
    {code:"13",label:"گیلانغرب"},
    {code:"14",label:"هرسین"}
  ],
  "23": [
    {code:"1",label:"باشت"},
    {code:"2",label:"بویراحمد"},
    {code:"3",label:"بهمئی"},
    {code:"4",label:"چرام"},
    {code:"5",label:"دنا"},
    {code:"6",label:"كهگیلویه"},
    {code:"7",label:"گچساران"},
    {code:"8",label:"لنده"}
  ],
  "24": [
    {code:"1",label:"آزادشهر"},
    {code:"2",label:"آق قلا"},
    {code:"3",label:"بندرگز"},
    {code:"4",label:"تركمن"},
    {code:"5",label:"رامیان"},
    {code:"6",label:"علی آباد"},
    {code:"7",label:"كردكوئ"},
    {code:"8",label:"كلاله"},
    {code:"9",label:"گالیكش"},
    {code:"10",label:"گرگان"},
    {code:"11",label:"گمیشان"},
    {code:"12",label:"گنبدكاووس"},
    {code:"13",label:"مراوه تپه"},
    {code:"14",label:"مینودشت"}
  ],
  "25": [
    {code:"1",label:"املش"},
    {code:"2",label:"آستارا"},
    {code:"3",label:"آستانه اشرفیه"},
    {code:"4",label:"بندرانزلی"},
    {code:"5",label:"رشت"},
    {code:"6",label:"رضوانشهر"},
    {code:"7",label:"رودبار"},
    {code:"8",label:"رودسر"},
    {code:"9",label:"سیاهكل"},
    {code:"10",label:"شفت"},
    {code:"11",label:"صومعه سرا"},
    {code:"12",label:"طوالش"},
    {code:"13",label:"فومن"},
    {code:"14",label:"لاهیجان"},
    {code:"15",label:"لنگرود"},
    {code:"16",label:"ماسال"}
  ],
  "26": [
    {code:"1",label:"ازنا"},
    {code:"2",label:"الیگودرز"},
    {code:"3",label:"بروجرد"},
    {code:"4",label:"پلدختر"},
    {code:"5",label:"خرم آباد"},
    {code:"6",label:"دلفان"},
    {code:"7",label:"دورود"},
    {code:"8",label:"دوره"},
    {code:"9",label:"رومشکان"},
    {code:"10",label:"سلسله"},
    {code:"11",label:"کوهدشت"}
  ],
  "27": [
    {code:"1",label:"آمل"},
    {code:"2",label:"بابل"},
    {code:"3",label:"بابلسر"},
    {code:"4",label:"بهشهر"},
    {code:"5",label:"تنكابن"},
    {code:"6",label:"جویبار"},
    {code:"7",label:"چالوس"},
    {code:"8",label:"رامسر"},
    {code:"9",label:"سارئ"},
    {code:"10",label:"سوادکوه شمالی"},
    {code:"11",label:"سوادكوه"},
    {code:"12",label:"سیمرغ"},
    {code:"13",label:"عباس آباد"},
    {code:"14",label:"فریدونكنار"},
    {code:"15",label:"قائم شهر"},
    {code:"16",label:"کلاردشت"},
    {code:"17",label:"گلوگاه"},
    {code:"18",label:"محمودآباد"},
    {code:"19",label:"میاندورود"},
    {code:"20",label:"نكا"},
    {code:"21",label:"نور"},
    {code:"22",label:"نوشهر"}
  ],
  "28": [
    {code:"1",label:"اراک"},
    {code:"2",label:"آشتیان"},
    {code:"3",label:"تفرش"},
    {code:"4",label:"خمین"},
    {code:"5",label:"خنداب"},
    {code:"6",label:"دلیجان"},
    {code:"7",label:"زرندیه"},
    {code:"8",label:"ساوه"},
    {code:"9",label:"شازند"},
    {code:"10",label:"فراهان"},
    {code:"11",label:"کمیجان"},
    {code:"12",label:"محلات"}
  ],
  "29": [
    {code:"1",label: "ابوموسی"},
    {code:"2",label: "بستك"},
    {code:"3",label: "بشاگرد"},
    {code:"4",label: "بندرعباس"},
    {code:"5",label: "بندرلنگه"},
    {code:"6",label: "پارسیان"},
    {code:"7",label: "جاسك"},
    {code:"8",label: "حاجی اباد"},
    {code:"9",label: "خمیر"},
    {code:"10",label: "رودان"},
    {code:"11",label: "سیریك"},
    {code:"12",label: "قشم"},
    {code:"13",label: "میناب"}
  ],
  "30": [
    {code:"1",label:"اسدآباد"},
    {code:"2",label:"بهار"},
    {code:"3",label:"تویسركان"},
    {code:"4",label:"رزن"},
    {code:"5",label:"فامنین"},
    {code:"6",label:"كبودرآهنگ"},
    {code:"7",label:"ملایر"},
    {code:"8",label:"نهاوند"},
    {code:"9",label:"همدان"}
  ],
  "31": [
    {code:"1",label:"ابركوه"},
    {code:"2",label:"اردكان"},
    {code:"3",label:"اشکذر"},
    {code:"4",label:"بافق"},
    {code:"5",label:"بهاباد"},
    {code:"6",label:"تفت"},
    {code:"7",label:"خاتم"},
    {code:"8",label:"مهریز"},
    {code:"9",label:"میبد"}
  ]
      },
      shahr:[
        {label:'استان را انتخاب کنید' , code:0}
      ],
      form:{
        ostan:null,
        shahr:null,
        addr : new String(),
        javaz:new String(),
        oldRepresentation:null,
        oldWork:new String(),
        cash:new String(),
        noneCash:new String(),
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    'ValidationObserver':VeeValidate.ValidationObserver,
  },
  methods: {
      setSelected(evt,prop) {
        this.form[prop] = evt.code;
        //console.log(evt);
        //console.log(this.form.age);
      },
      async validate(){
        const isValid = await this.$refs.form.validate();
        //console.log(isValid);
        return isValid;
      },
      fetchShahr(){
        this.form.shahr = "0";
        this.shahr = this.shahrSample[this.form.ostan];
        //return true;
      }
  }
}
</script>

<style lang="css" scoped>
</style>
